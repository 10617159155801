import { Patch } from './Patch';
import { Stack } from './Stack';
import { Hook } from './UndoManagerHook';

type UndoManagerOptions = {
  stackLimit?: number;
  autoPatch?: boolean;
  compose?: {
    shouldCompose: boolean;
    interval?: number;
  };
};

type UndoManagerHooks<
  T extends Realtime.Core.UndoManager.AvailableHooks = Realtime.Core.UndoManager.AvailableHooks,
> = Record<T, Hook<T>>;

export class UndoManager {
  private undoStack: Stack;
  private redoStack: Stack;
  private patch?: Patch;
  private options: Required<UndoManagerOptions>;
  hooks: UndoManagerHooks;

  private debug: boolean = false;

  constructor(options?: UndoManagerOptions) {
    this.options = {
      stackLimit: 150,
      autoPatch: false,
      compose: {
        shouldCompose: false,
      },
      ...options,
    };
    this.hooks = {
      onCreatedPatch: new Hook(),
      onFinishingPatch: new Hook(),
      beforePatchApply: new Hook(),
      afterPatchApply: new Hook(),
      beforePatchRevert: new Hook(),
      afterPatchRevert: new Hook(),
      onUndoStatusChanged: new Hook<'onUndoStatusChanged'>(),
      onRedoStatusChanged: new Hook<'onRedoStatusChanged'>(),
    };
    this.undoStack = new Stack({
      debug: this.debug,
      limit: this.options.stackLimit,
      onStatusChanged: this.hooks.onUndoStatusChanged as Hook<'onUndoStatusChanged'>,
    });
    this.redoStack = new Stack({
      debug: this.debug,
      limit: this.options.stackLimit,
      onStatusChanged: this.hooks.onRedoStatusChanged as Hook<'onRedoStatusChanged'>,
    });
  }

  private getExistingPatch() {
    if (!this.patch) {
      this.patch = new Patch();
      this.hooks.onCreatedPatch.trigger(this.patch);
    }
    return this.patch;
  }

  canUndo() {
    return !this.undoStack.isEmpty;
  }

  canRedo() {
    return !this.redoStack.isEmpty;
  }

  onDocOperation(
    doc: Realtime.Core.RealtimeObject,
    ops: Realtime.Core.RealtimeOps,
    options: Realtime.Core.RealtimeSourceOptions,
  ) {
    if (ops.length <= 0) {
      return;
    }
    if (options.source === true || options.source === 'LOCAL_RENDER') {
      this.redoStack.clear();
      const patch = this.getExistingPatch();
      patch.add(doc, ops);
      if (this.options.autoPatch) {
        this.createPatch();
      }
    } else if (!options.source) {
      this.undoStack.transformStack(doc, ops);
      this.redoStack.transformStack(doc, ops);
    }
  }

  protected shouldCompose() {
    if (!this.options.compose.shouldCompose) {
      return false;
    }
    if (!this.patch) {
      return false;
    }
    if (this.undoStack.isEmpty) {
      return false;
    }
    if (
      this.patch.t.created - this.undoStack.getLast().t.created >
      (this.options.compose.interval || 500)
    ) {
      return false;
    }
    return true;
  }

  protected handleCompose() {}

  createPatch() {
    if (this.patch && !this.patch.isEmpty) {
      if (this.debug) {
        logger.trace('UndoManager createPatch', this.patch);
      }
      this.hooks.onFinishingPatch.trigger(this.patch);
      if (this.shouldCompose()) {
        if (!this.undoStack.getLast().composeWithPatch(this.patch)) {
          this.undoStack.setLast(this.patch);
        }
      } else {
        this.undoStack.setLast(this.patch);
      }
      this.patch = undefined;
    }
  }

  async undo() {
    if (this.canUndo()) {
      await this.hooks.beforePatchRevert.trigger(this.undoStack.getLast());
      const patch = this.undoStack.pop();
      if (this.debug) {
        logger.info('UNDO', patch);
      }
      if (patch && !patch.isEmpty) {
        try {
          if (this.debug) {
            logger.debug('pre revert');
          }
          await patch.revert();
          if (this.debug) {
            logger.debug('pos revert');
          }
          this.redoStack.setLast(patch);
          if (this.debug) {
            logger.debug('pos set last');
          }
        } catch (error) {
          logger.error('Error Undo', patch);
        }
        await this.hooks.afterPatchRevert.trigger(patch);
      }
    }
  }

  async redo() {
    if (this.canRedo()) {
      await this.hooks.beforePatchApply.trigger(this.redoStack.getLast());
      const patch = this.redoStack.pop();
      if (this.debug) {
        logger.info('REDO', patch);
      }
      if (patch && !patch.isEmpty) {
        try {
          await patch.apply();
          this.undoStack.setLast(patch);
        } catch (error) {
          logger.error('Error Redo', patch);
        }

        await this.hooks.afterPatchApply.trigger(patch);
      }
    }
  }

  clearUndoRedo() {
    if (this.debug) {
      logger.trace('UndoManager clearUndoRedo');
    }
    this.undoStack.clear();
    this.redoStack.clear();
  }
}
